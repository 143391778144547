import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"   transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">


<path d="M8295 10240 c-77 -4 -147 -11 -156 -14 -57 -24 -71 -283 -23 -421 16
-45 46 -104 66 -130 55 -69 113 -155 148 -220 24 -44 47 -87 52 -95 13 -23 83
-209 100 -265 39 -126 47 -152 53 -185 3 -19 12 -64 20 -100 61 -289 53 -748
-19 -1070 -51 -225 -134 -421 -308 -717 -7 -12 -40 -66 -73 -120 l-60 -98 -2
-460 c-2 -253 -5 -469 -8 -481 -11 -49 -20 -49 -1030 -49 -879 0 -945 1 -1015
18 -221 55 -338 117 -479 256 -134 132 -207 261 -258 456 -26 101 -26 425 0
540 36 155 96 279 201 416 96 124 199 201 356 267 163 68 197 73 520 82 284 8
330 15 398 60 l22 15 0 397 0 398 -22 4 c-124 23 -201 30 -368 34 -120 2 -231
-1 -285 -8 -50 -6 -125 -15 -167 -20 -43 -5 -92 -13 -110 -18 -18 -6 -73 -20
-123 -31 -232 -56 -476 -174 -668 -324 -102 -79 -256 -233 -297 -296 -8 -13
-20 -29 -25 -35 -18 -19 -84 -123 -95 -147 -6 -13 -20 -40 -31 -59 -24 -39
-99 -208 -99 -223 0 -5 -6 -23 -14 -40 -38 -89 -73 -230 -103 -417 -26 -161
-24 -582 5 -745 29 -170 58 -280 97 -370 7 -16 21 -50 30 -75 9 -25 21 -52 25
-60 5 -8 23 -44 41 -80 124 -254 355 -500 608 -647 185 -108 429 -198 641
-237 242 -44 309 -46 1732 -46 1296 0 1355 1 1388 18 21 12 37 30 42 48 11 36
14 5220 4 5252 -4 13 -20 28 -34 33 -34 13 -498 20 -677 9z"/>
<path d="M3995 10228 c-55 -5 -133 -6 -173 -3 -68 6 -74 4 -98 -20 -19 -19
-23 -31 -17 -43 4 -9 10 -1188 13 -2619 4 -1899 8 -2605 16 -2613 6 -6 47 -21
90 -33 70 -18 106 -21 309 -21 162 1 264 6 343 17 62 10 115 17 118 17 3 0 15
21 26 47 42 94 19 355 -37 423 -28 34 -85 120 -85 129 0 5 -3 11 -7 13 -32 14
-169 296 -222 458 -67 203 -71 217 -71 243 0 13 -7 44 -15 67 -8 24 -20 79
-25 124 -5 45 -14 113 -20 151 -12 86 -11 358 0 440 5 33 14 102 19 153 6 51
15 100 20 110 5 9 12 40 16 67 4 28 13 68 20 90 7 22 32 101 55 175 40 127
143 357 199 445 14 22 30 47 36 57 5 9 32 51 59 93 74 116 76 127 76 605 0
454 -2 436 60 490 l24 21 940 -3 c1004 -4 971 -2 1136 -54 205 -65 397 -205
505 -369 61 -93 115 -223 115 -279 0 -14 5 -37 11 -53 15 -40 26 -261 19 -373
-7 -99 -32 -223 -56 -277 -8 -17 -14 -35 -14 -40 0 -12 -44 -98 -52 -101 -5
-2 -8 -10 -8 -17 0 -16 -100 -147 -149 -195 -52 -52 -126 -110 -140 -110 -6 0
-11 -4 -11 -8 0 -13 -125 -72 -204 -97 -124 -39 -254 -53 -538 -59 -157 -3
-275 -9 -287 -15 -12 -7 -24 -29 -30 -58 -42 -184 -35 -654 11 -775 9 -23 17
-28 61 -32 29 -3 96 -10 149 -16 53 -5 143 -10 200 -10 111 0 378 24 443 39
22 5 78 17 124 26 88 18 241 64 301 92 175 79 301 149 415 232 65 48 285 257
285 271 0 4 9 18 21 31 23 26 106 149 126 188 28 52 61 118 76 151 31 71 80
208 101 280 8 30 22 78 31 107 8 28 15 61 15 73 0 11 7 53 15 93 24 119 36
375 24 543 -9 136 -40 352 -54 375 -4 5 -10 30 -15 57 -8 47 -16 71 -52 167
-42 110 -130 288 -181 368 -149 233 -376 431 -652 570 -147 74 -216 98 -500
168 -27 6 -90 16 -140 21 -118 13 -2638 18 -2770 6z"/>
<path d="M3784 4585 c-2 -5 -13 -71 -24 -145 -11 -74 -26 -180 -35 -235 -22
-146 -26 -135 55 -135 l69 0 6 33 c3 17 9 52 13 77 l7 45 85 6 c86 6 132 22
161 55 55 62 70 171 34 233 -34 57 -71 69 -229 73 -86 2 -140 -1 -142 -7z
m220 -131 c21 -8 20 -52 -1 -76 -11 -12 -30 -18 -60 -18 -40 0 -43 2 -43 28 0
59 10 72 50 72 21 0 45 -3 54 -6z"/>
<path d="M4254 4585 c-5 -13 -74 -480 -74 -500 0 -12 14 -15 70 -15 l69 0 6
33 c3 17 9 55 13 82 6 44 10 50 31 50 21 0 28 -10 51 -80 l27 -80 81 -3 c48
-2 82 1 82 7 0 5 -16 47 -35 92 -19 45 -34 83 -32 84 78 47 111 109 105 194
-5 67 -27 103 -78 125 -42 18 -310 28 -316 11z m244 -155 c5 -41 -19 -62 -76
-68 -52 -5 -52 -4 -52 24 0 68 7 75 69 72 51 -3 56 -5 59 -28z"/>
<path d="M4764 4585 c-5 -12 -74 -482 -74 -501 0 -11 35 -14 195 -14 183 0
195 1 195 19 0 10 3 39 6 65 l7 46 -115 0 c-63 0 -117 3 -120 6 -3 3 -3 17 1
32 l7 27 111 3 110 3 7 37 c3 20 6 49 6 65 l0 27 -111 0 c-111 0 -111 0 -105
23 3 12 6 25 6 30 0 4 54 7 120 7 87 0 122 3 125 13 3 6 6 36 8 65 l2 53 -189
2 c-119 1 -190 -2 -192 -8z"/>
<path d="M5235 4586 c-6 -15 -75 -481 -75 -501 0 -12 13 -15 69 -15 l70 0 7
48 c3 26 12 76 18 112 l11 65 49 -83 c67 -115 71 -114 204 38 17 19 32 33 35
30 3 -3 -1 -45 -9 -95 -7 -49 -14 -96 -14 -102 0 -9 20 -13 70 -13 l69 0 6 33
c19 105 72 483 68 490 -3 4 -28 7 -56 5 l-51 -3 -104 -120 c-57 -66 -108 -120
-113 -120 -5 0 -40 54 -77 120 l-67 119 -53 2 c-32 1 -55 -3 -57 -10z"/>
<path d="M5959 4592 c-46 -3 -46 -3 -52 -45 -4 -23 -20 -134 -37 -246 -16
-112 -30 -210 -30 -218 0 -11 15 -13 72 -11 l73 3 21 150 c12 83 29 197 38
254 8 58 13 107 11 110 -7 6 -40 7 -96 3z"/>
<path d="M6195 4591 l-49 -6 -23 -145 c-28 -172 -27 -267 3 -307 61 -84 223
-95 323 -23 63 46 87 109 116 304 13 93 23 172 21 175 -2 3 -35 6 -72 6 l-69
-1 -26 -172 c-15 -95 -32 -179 -38 -186 -19 -22 -69 -39 -96 -32 -38 10 -41
45 -16 219 13 87 20 161 17 165 -9 8 -33 9 -91 3z"/>
<path d="M6683 4592 c-8 -6 -83 -464 -83 -509 0 -9 21 -13 69 -13 l70 0 7 48
c3 26 12 76 18 112 l11 65 49 -83 c37 -63 53 -82 69 -82 29 0 32 3 106 88 35
40 66 71 68 69 2 -3 -3 -46 -12 -98 -8 -52 -15 -100 -15 -106 0 -9 21 -13 70
-13 39 0 70 1 71 3 9 38 75 514 72 520 -3 4 -27 7 -55 5 -49 -3 -53 -6 -107
-68 -31 -35 -71 -82 -90 -102 -19 -21 -40 -47 -48 -58 -8 -12 -19 -20 -24 -18
-6 2 -40 57 -77 123 l-67 119 -45 2 c-25 2 -51 0 -57 -4z"/>
<path d="M8023 4588 c-10 -12 -151 -290 -229 -450 l-33 -68 74 0 74 0 20 45
20 46 78 -3 77 -3 9 -42 8 -43 73 0 73 0 -34 173 c-18 94 -41 211 -50 260
l-17 87 -52 0 c-29 0 -59 2 -67 5 -7 3 -19 0 -24 -7z m62 -250 l6 -58 -39 0
c-21 0 -41 3 -45 6 -3 3 8 35 26 70 35 71 41 69 52 -18z"/>
<path d="M8525 4590 l-210 -5 -7 -40 c-4 -22 -7 -53 -7 -68 l-1 -28 65 2 c36
1 67 0 69 -2 3 -3 -32 -255 -49 -346 l-6 -33 74 0 74 0 16 103 c9 56 22 141
29 190 l12 87 67 0 c37 0 70 4 73 8 9 16 24 127 18 132 -4 3 -101 3 -217 0z"/>
<path d="M8893 4591 c-7 -5 -232 -450 -255 -503 -7 -16 0 -18 67 -18 l74 0 20
45 20 45 79 0 79 0 6 -41 c3 -22 7 -43 10 -45 3 -3 36 -4 74 -2 l69 3 -50 255
c-27 140 -50 257 -52 259 -5 6 -132 8 -141 2z m62 -253 l7 -58 -41 0 c-50 0
-51 9 -14 85 31 62 38 58 48 -27z"/>
<path d="M7322 4378 c-18 -117 -35 -235 -39 -261 l-6 -49 154 4 c135 3 160 6
204 26 100 46 151 126 162 252 8 90 -24 165 -88 207 -41 27 -47 28 -199 31
l-156 4 -32 -214z m309 42 c24 -33 24 -81 -1 -131 -28 -54 -72 -79 -138 -79
-46 0 -53 2 -48 18 3 9 10 55 16 102 17 128 16 127 90 120 50 -5 65 -11 81
-30z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
